import React, { useEffect, useState } from 'react';

import { Link } from 'gatsby';

import Logo from '../svg/logo.svg';
import LogoColor from '../svg/logo_c.svg';

import axios from 'axios'

import Moment from 'react-moment'



const Header = (props) => {

    let returnUrl = props.pbUri

    const [allPostData, setAllPostData] = useState('');
    const [loadNews, setLoadData] = useState(false);

    useEffect(() => {

        let abortController = new AbortController();

        // get post data
        async function getAllPostData(limit){
            await axios.get(process.env.API_URL + `/news?limit=${limit}`, { 
                headers: {
                    'Content-type': 'application/json',
                    'X-API-KEY': process.env.API_KEY
                },
            }).then(result =>{
                // console.log(result)
                
                const post = result.data.contents

                setAllPostData(post)
        
            }).catch(error =>{
                // console.log(error)
            })
        }

        getAllPostData(5)



        const news = document.querySelector('.js-loadnews');

        if(news != null){

            if(loadNews !== false){

                var target = news;
                var targetNow = target.getElementsByClassName('is-disp');
                var num = target.length;
                var timer = 8000

                target.firstElementChild.classList.add("is-disp");

                setInterval(function(){
                    if(targetNow.length){
                        num--;
                        var now = target.querySelector('.is-disp');

                        Array.prototype.forEach.call(targetNow, function(element) {
                            element.classList.remove('is-disp');
                        });

                        if(num <= 0){
                            targetNow.firstElementChild.classList.add("is-disp");
                            num = target.length;
                        }else{
                            if(!now.nextElementSibling){
                                target.firstElementChild.classList.add("is-disp");
                            }else{
                                now.nextElementSibling.classList.add("is-disp");
                            }
                        }

                        return;
                    }
                },timer);
            }
        }

        return () => {
            abortController.abort()
            setLoadData(true)
            clearInterval(timer)
        };

    }, [loadNews]);

    const positions = props.position;



    return(
        <>
            <header className={`l-header ${(positions === '/') ? '' : 'is-sub'}`}>
                <div className="l-header-inner">

                    <div className="l-header-logo">
                        <Link to="/" title="アクロスソリューションズ" className="l-header-logo--btn">
                            {(() => {
                                if(positions === '/'){
                                    return(
                                        <>
                                        <Logo />
                                        </>
                                    )
                                }else{
                                    return(
                                        <>
                                        <LogoColor />
                                        </>
                                    )
                                }
                            })()}
                        </Link>
                    </div>

                    <div className="l-header-nav">
                        <div className={`l-header-nav-cmd ${(positions === '/') ? 'is-home' : ''}`}>
                            <Link to="/solution" title="SOLUTION" className="l-header-nav-cmd--btn u-eng">
                            SOLUTION
                            </Link>

                            <Link to="/mission" title="MISSION" className="l-header-nav-cmd--btn u-eng">
                            MISSION
                            </Link>

                            <Link to="/vision" title="COMPANY" className="l-header-nav-cmd--btn u-eng">
                            COMPANY
                            </Link>

                            <Link to="/recruit" title="RECRUIT" className="l-header-nav-cmd--btn u-eng">
                            RECRUIT
                            </Link>
                        </div>

                        <div className={`l-header-nav-news ${(positions === '/') ? 'is-home' : ''}`}>
                            <div className="l-header-nav-news-title">
                                <p className="l-header-nav-news-title--text u-eng">
                                NEWS
                                </p>
                            </div>

                            <div className="l-header-nav-news-area js-loadnews">

                                {(() =>{
                                if(allPostData?.length){
                                    return(
                                        <>

                                            {(() => {
                                            if(loadNews === false ){
                                                setLoadData(true)
                                            }
                                            })()}

                                            {allPostData.map((val,index) => {
                                                return(
                                                    <React.Fragment key={index}>
                                                        <Link to={`/notice/${val.id}`} title={val.title} className="l-header-nav-news-area--link" state={{refUrl:`${returnUrl}`}}>
                                                            <span className="is-date"><Moment format="YYYY/MM/DD">{val.postDate}</Moment></span><span className="is-text">{val.title}</span>
                                                        </Link>
                                                    </React.Fragment>
                                                )
                                            })}

                                        </>
                                    )
                                }else{
                                    return(
                                        <>

                                            <p className="l-header-nav-news-area--link is-disp">
                                                現在準備中です。読み込み完了、または次回リリースまでお待ちください。
                                            </p>

                                        </>
                                    )
                                }
                                })()}

                            </div>

                            <div className="l-header-nav-news-archive">
                                <Link to="/notice" title="NEWS" className="l-header-nav-news-archive--btn u-josef">
                                ALL
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </header>
        </>
    )
}

export default Header;


