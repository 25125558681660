import React from 'react';

import { StaticImage } from "gatsby-plugin-image";

import { Link } from 'gatsby';

const Footer = (props) => {

    const positions = props.position;

    return(
        <>

            <footer className={`l-footer ${(positions === '/') ? 'is-home' : ''}`}>
                <div className="l-footer-inner">

                    {(() => {
                        if(positions === '/'){
                            return(
                                <>
                                    <div className="l-footer-bnr">
                                        <Link to="https://www.sts-inc.co.jp/" title="株式会社システムサポート">
                                            <StaticImage
                                            src="../images/sts_group_logo_w.png"
                                            alt="システムサポートグループ"
                                            placeholder="blurred"
                                            quality="100"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )
                        }else{
                            return(
                                <>
                                    <div className="l-footer-bnr">
                                        <Link to="https://www.sts-inc.co.jp/" title="株式会社システムサポート">
                                            <StaticImage
                                            src="../images/sts_group_logo.png"
                                            alt="システムサポートグループ"
                                            placeholder="blurred"
                                            quality="100"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    })()}

                    <div className="l-footer-box">
                        <div className="l-footer-nav">
                            <Link to="/access" title="ACCESS" className="l-footer-nav--btn u-eng">
                            ACCESS
                            </Link>

                            <Link to="/contact" title="CONTACT" className="l-footer-nav--btn u-eng">
                            CONTACT
                            </Link>

                            <Link to="/policy" title="POLICY" className="l-footer-nav--btn u-eng">
                            POLICY
                            </Link>

                            <Link to="/security" title="SECURITY" className="l-footer-nav--btn u-eng">
                            SECURITY
                            </Link>

                            <Link to="/specific" title="SPECIFIC" className="l-footer-nav--btn u-eng">
                            SPECIFIC
                            </Link>

                            <Link to="/public_notice" title="PUBLIC" className="l-footer-nav--btn u-eng">
                            PUBLIC
                            </Link>
                        </div>

                        <div className="l-footer-copyright">
                            <p className="l-footer-copyright--text u-eng">
                            Copyright &copy; ACROSS Solutions,INC. All Rights Reserved.
                            </p>
                        </div>
                    </div>

                </div>
            </footer>

        </>
    )
}

export default Footer;


